export default [
    {
        elementType: "labels",
        stylers: [
            {
                color: "#ffffff",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        elementType: "labels.text.fill",
        stylers: [
            {
                color: "#ffffff",
            },
        ],
    },
    {
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape",
        stylers: [
            {
                color: "#2E3D4A",
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
            {
                color: "#171f26",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
            {
                color: "#171f26",
            },
        ],
    },

    {
        featureType: "road.local",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "geometry",
        stylers: [
            {
                color: "#171f26",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.stroke",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        stylers: [
            {
                color: "#B7D6EE",
            },
            {
                visibility: "on",
            },
        ],
    },
];
