export default function Development({ title, description, types, prices, image, link }) {
    const formatPrice = (price) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            maximumFractionDigits: 0
        });

        return formatter.format(price);
    };

    return (
        <div className="devolpment-result">
            <div className="devolpment-result__media">
                <div className="devolpment-result__image">
                    {image && <img src={image} />}
                </div>
                {prices.from && (
                    <div className="devolpment-result__price">
                        <span>from</span> {formatPrice(prices.from)}
                    </div>
                )}
            </div>
            <div className="devolpment-result__details">
                <h3 className="devolpment-result__title">{title}</h3>
                <p className="devolpment-result__description">{description}</p>
                <a href={link} className="devolpment-result__link">
                    View Development
                </a>
            </div>
        </div>
    );
}
