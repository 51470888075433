export default function ActiveLocation({ title, description}) {
    return (
        <div className="location-result">
            <div className="location-result__content">
                <h2 className="location-result__title">
                    New Builds in {title}
                </h2>
                <div className="location-result__description" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
        </div>
    );
}
