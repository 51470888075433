import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

const rootEl = document.getElementById("search-locations");
if (rootEl) {
    const root = createRoot(rootEl);
    root.render(
        <StrictMode>
            <App />
        </StrictMode>
    );
}
