import React, { useState, useEffect } from "react";
import {
    signal,
    computed,
    useSignalEffect,
    effect,
} from "@preact/signals-react";
import axios from "axios";

import Map from "./Map";
import Development from "./Development";
import LoadingSpinner from "./LoadingSpinner";
import Hero from "./Hero";
import ActiveLocation from "./ActiveLocation";

let centerLocation = { lat: 0, lng: 0 };

if (
    typeof window.locationSearch !== "undefined" &&
    typeof window.locationSearch.gmap !== "undefined" &&
    typeof window.locationSearch.gmap.center !== "undefined"
) {
    centerLocation = {
        lat: window.locationSearch.gmaps.center.lat,
        lng: window.locationSearch.gmaps.center.lng,
    };
}

// State
const query = signal("");
const queryCoords = signal({ lat: 0, lng: 0 });
const center = signal(centerLocation);
const results = signal([]);
const loaded = computed(() => results.value.length > 0);
const developments = signal([]);
const apiKey = signal(false);

const activeLocation = computed(() => {
    const defaultLocation = { title: "", description: "" };
    if (developments.value.length === 0 || query.value == "")
        return defaultLocation;

    const filteredLocations = results.value.filter(
        (location) =>
            location.developments.filter((development) =>
                developments.value.includes(development)
            ).length > 0
    );

    if (filteredLocations.length > 0) return filteredLocations[0];

    return defaultLocation;
});

const developmentList = computed(() =>
    developments.value.map(
        ({ id, title, description, types, prices, image, permalink }) => (
            <Development
                key={id}
                title={title}
                description={description}
                types={types}
                prices={prices}
                image={image}
                link={permalink}
            />
        )
    )
);

const map = computed(() => {
    return apiKey.value !== false && results.value.length > 0 ? (
        <Map
            developments={developments}
            center={center}
            query={query}
            queryCoords={queryCoords}
            apiKey={apiKey}
        />
    ) : (
        <LoadingSpinner />
    );
});

const searchResultMessage = computed(() => {
    if (developments.value.length !== 0) {
        if (queryCoords.value.lat !== 0 && queryCoords.value.lng !== 0) {
            if (query.value !== "") {
                return `${developments.value.length} ${
                    developments.value.length === 1
                        ? "Development"
                        : "Developments"
                } near "${query.value}"`;
            } else {
                return `${developments.value.length} ${
                    developments.value.length === 1
                        ? "Development"
                        : "Developments"
                } near your location`;
            }
        } else {
            return "Showing all developments";
        }
    } else {
        return "No developments found";
    }
});

const showActiveLocation = computed(() => {
    if (
        activeLocation.value.title !== "" &&
        activeLocation.value.description !== ""
    ) {
        return (
            <ActiveLocation
                title={activeLocation.value.title}
                description={activeLocation.value.description}
            />
        );
    }
});

export default function LocationSearch() {
    const getCurrentLocation = () => {
        query.value = "";

        navigator.geolocation.getCurrentPosition((position) => {
            queryCoords.value = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            };
        });

        queryCoords.value = { lat: 0, lng: 0 };
    };

    useSignalEffect(() => {
        if (results.value.length === 0) {
            axios.get(`/wp-json/durkan/v1/location`).then(({ data }) => {
                results.value = data;
            });
        }
    });

    useSignalEffect(() => {
        if (apiKey.value === false) {
            axios.get(`/wp-json/durkan/v1/key`).then(({ data }) => {
                if (data.gmap !== null) {
                    apiKey.value = data.gmap;
                }
            });
        }
    })

    effect(async () => {
        let output = [];

        results.value.map((location) => {
            location.developments.map((development) => {
                const inOuputAlready = output.filter((dev) => dev.id === development.id).length > 0;
                if (!inOuputAlready) output.push(development);
            });
        });

        if (queryCoords.value.lat !== 0 && queryCoords.value.lng !== 0) {
            const { spherical } = await google.maps.importLibrary("geometry");
            output = output.filter((development) => {
                const radius = spherical.computeDistanceBetween(
                    new google.maps.LatLng(
                        queryCoords.value.lat,
                        queryCoords.value.lng
                    ),
                    new google.maps.LatLng(
                        development.location.lat,
                        development.location.lng
                    )
                );

                return radius * 0.000621371 < 20;
            });
        }

        developments.value = output;
    });

    const setQuery = () => {
        queryCoords.value.lat = 0;
        queryCoords.value.lng = 0;
        const inputQuery = document.getElementById("location-search").value;
        query.value = inputQuery.length > 3 ? inputQuery : "";

        if (
            query.value === "" &&
            queryCoords.value.lat === 0 &&
            queryCoords.value.lng === 0
        ) {
            const output = [];

            results.value.map((location) => {
                location.developments.map((development) => {
                    output.push(development);
                });
            });

            developments.value = output;
            document.getElementById("location-search").value = "";
        }
    };

    return (
        <div className="locations">
            {developments.length}
            <div className="locations__container">
                <Hero />

                <div className="locations__main">
                    <aside className="locations__sidebar">
                        <div className="locations__search">
                            <label htmlFor="location-search">
                                Enter location
                            </label>
                            <input
                                type="text"
                                id="location-search"
                                className="locations__search-input"
                                placeholder="Town or postcode..."
                                onKeyDown={(e) =>
                                    e.key === "Enter" && setQuery()
                                }
                            />
                            <button
                                id="location-search-button"
                                className="locations__search-button"
                                onClick={setQuery}
                            >
                                Search
                            </button>
                            <button
                                id="location-search-my-location"
                                className="locations__search-my-location"
                                onClick={getCurrentLocation}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M20 9.38095C20 16 12 23 12 23C12 23 4 16 4 9.38095C4 4.75228 7.58172 1 12 1C16.4183 1 20 4.75228 20 9.38095Z"
                                        fill="#F5416C"
                                    />
                                    <circle
                                        cx="12"
                                        cy="9"
                                        r="4"
                                        fill="#F4F2F2"
                                    />
                                </svg>
                                <span>My location</span>
                            </button>
                        </div>

                        <div className="locations__results">
                            {loaded ? (
                                <>
                                    <h2 className="locations__results-title">
                                        {searchResultMessage}
                                    </h2>
                                    <div className="locations__results-scroller">
                                        <div
                                            id="location-results"
                                            className="locations__results-list"
                                        >
                                            {developmentList}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <LoadingSpinner />
                            )}
                        </div>
                    </aside>

                    <div className="locations__map">{map}</div>
                </div>

                {showActiveLocation}
            </div>
        </div>
    );
}
