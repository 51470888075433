import { memo, useCallback } from "react";
import { signal, computed, effect } from "@preact/signals-react";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    MarkerClusterer,
    InfoWindow,
} from "@react-google-maps/api";

// Styles
import mapStyles from "../mapStyles";
const containerStyle = {
    width: "100%",
    height: "100%",
};

// State
const activeMarker = signal(null);
const map = signal(null);
const mapLoaded = computed(() => map.value !== null);

const svgMarkerPath =
    "M50.2,8.9L50.2,8.9c-11.4-11.4-30-11.4-41.4,0c-11.4,11.4-11.4,30,0,41.4L29.5,71l20.7-20.7 C61.5,38.9,61.5,20.4,50.2,8.9z M29.5,55.8l-13-13c-7.1-7.1-7.1-18.9,0-26.2c3.6-3.6,8.3-5.4,13-5.4c4.7,0,9.5,1.8,13.2,5.4 c7.2,7.2,7.1,18.9-0.2,26.2L29.5,55.8z";

function Map({ developments, center, query, queryCoords, apiKey }) {
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: apiKey.value,
    });

    const myLocation = computed(() => {
        return (
            queryCoords.value.lat !== 0 &&
            queryCoords.value.lng !== 0 && (
                <Marker
                    position={queryCoords.value}
                    animation={google.maps.Animation.DROP}
                    icon={{
                        scaledSize: new google.maps.Size(25, 25),
                        path: svgMarkerPath,
                        fillColor: "#90b8e1",
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: 0.5,
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(12.5, 25),
                    }}
                />
            )
        );
    });

    const MarkersEls = computed(() => {
        return developments.value.map((development) => {
            if (development.location) {
                return (
                    <Marker
                        key={development.id}
                        position={development.location}
                        icon={{
                            scaledSize: new google.maps.Size(25, 25),
                            path: svgMarkerPath,
                            fillColor: "#FFC629",
                            fillOpacity: 1,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 0.5,
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(12.5, 25),
                        }}
                        animation={google.maps.Animation.DROP}
                        onClick={() => handleActiveMarker(development.id)}
                    >
                        {activeMarker.value === development.id ? (
                            <InfoWindow
                                key={development.id}
                                onCloseClick={() => (activeMarker.value = null)}
                            >
                                <div className="devolpment-result__details">
                                    <h3 className="devolpment-result__title">{development.title}</h3>
                                    {development.street != '' || development.town != '' || development.county != '' || development.postcode != '' ? (
                                        <p className="devolpment-result__address">
                                            {development.street ? `${development.street}, ` : ''}
                                            {development.town ? `${development.town}, ` : ''}
                                            {development.county ? `${development.county}, ` : ''}
                                            {development.postcode ? development.postcode : ''}
                                        </p>
                                    ) : null}
                                    <p className="devolpment-result__description">{development.description}</p>
                                    <a href={development.permalink} className="devolpment-result__link">
                                        View Development
                                    </a>
                                </div>
                            </InfoWindow>
                        ) : null}
                    </Marker>
                );
            }
        });
    });

    const onLoad = useCallback(function callback(mapEl) {
        const bounds = new window.google.maps.LatLngBounds(center.value);
        mapEl.center = bounds.getCenter();
        mapEl.zoom = 9;

        map.value = mapEl;
    }, []);

    const onUnmount = useCallback(function callback(map) {
        map.value = null;
    }, []);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker.value) return;
        activeMarker.value = marker;
    };

    const geocodeQuery = async () => {
        if (query.value == "") return;

        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address: query.value }, (results, status) => {
            if (status === "OK") {
                queryCoords.value = {
                    lat: results[0].geometry.location.lat(),
                    lng: results[0].geometry.location.lng(),
                };
            }
        });
    };

    const updateDevelopmentMarkers = async () => {
        if (!isLoaded && !mapLoaded.value) return;

        let bounds = new window.google.maps.LatLngBounds();
        let activeDevelopments = developments.value.filter(
            (development) => development.location
        );

        if (queryCoords.value.lat !== 0 && queryCoords.value.lng !== 0) {
            bounds.extend(
                new google.maps.LatLng(
                    queryCoords.value.lat,
                    queryCoords.value.lng
                )
            );
        }

        if (activeDevelopments.length > 0) {
            activeDevelopments.map((development) =>
                bounds.extend(
                    new window.google.maps.LatLng(
                        development.location.lat,
                        development.location.lng
                    )
                )
            );
        }

        if (mapLoaded.value) {
            if (
                developments.value.length === activeDevelopments.length &&
                queryCoords.value.lat === 0 &&
                queryCoords.value.lng === 0
            ) {
                (developments.values || [])
                    .filter((development) => development.location)
                    .map((development) =>
                        bounds.extend(
                            new window.google.maps.LatLng(
                                development.location.lat,
                                development.location.lng
                            )
                        )
                    );

                map.value.center = bounds.getCenter();
                map.value.zoom = 9;
            } else {
                map.value.fitBounds(bounds, 100);
            }
        }
    };

    effect(geocodeQuery);
    effect(updateDevelopmentMarkers);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center.value}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                streetViewControl: false,
                scaleControl: true,
                styles: mapStyles,
                minZoom: 5,
                maxZoom: 15,
                mapTypeControl: false,
                fullscreenControl: false,
                keyboardShortcuts: false,
            }}
        >
            <MarkerClusterer maxZoom={3}>
                {(clusterer) => <>{MarkersEls}</>}
            </MarkerClusterer>

            {myLocation}
        </GoogleMap>
    ) : null;
}

export default memo(Map);
